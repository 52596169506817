import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import block from 'bem-cn';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { useLocation, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import {
  StoryCardMessage,
  StoryScheduleModal,
  StoryShareModal
} from '@features/stories/components';
import {
  useCurrentStoriesSlice,
  useFetchStories,
  useGetCurrentGroup,
  useGetStoriesParams,
  useIsDefaultLanguageChoosen,
  useLogicModeOff,
  useSaveChanges,
  useSaveLastPosition
} from '@features/stories/hooks';
import {
  checkIsNotInput,
  fetchUpdateOnboarding,
  fetchUpdateTemplate,
  getReducedValue,
  GroupsType,
  informSlice,
  MessageTypes,
  SIZE_PRESETS,
  SizePreset,
  SizePresetKeys,
  StoryEditorPreview,
  Templates
} from '@features';
import { EditorMode, EditorStoriesMode, ScheduleTimeType, Story } from '@features/stories/types';
import { StoryEditorAction, useStoryEditor, useStoryEditorDispatch } from '@modules';
import { BackgroundColorType } from '@storysdk/react';
import { useCardScale, useCardSize, useCurrentSizePreset, useElementSize } from '@hooks';
import { fetchUpdateLayersGroupSchedule, storiesSlice } from '../../storiesSlice';
import AddStory from '../AddStory/AddStory';
import { StoryEditor } from '../StoryEditor';
import './EditorStories.scss';

const b = block('EditorStories');

const CMD_KEY_CODE = 91;
const CONTROL_KEY_CODE = 17;
export const CARD_WIDTH_INIT = 360;
export const LARGE_WIDTH_SIZE_INDEX = 0.338;
export const BACKGROUND_HEIGHT_SIZE_INDEX = 0.732;
const TOOGLE_HEIGHT = 65;
export const INIT_CARD_TOP = 64;
export const INIT_LARGE_CARD_TOP = 17;
export const INIT_SMALL_CARD_LARGE_TOP = 66;
export const INIT_LARGE_CARD_PADDING = 169;
const BACKGROUND_BORDER_RADIUS = 45;
const HIDDEN_BORDER_RADIUS = 65;

interface EditorStoriesProps {
  mode?: EditorStoriesMode;
}

export const EditorStories: React.FC<EditorStoriesProps> = () => {
  const dispatch = useAppDispatch();

  const { appId, groupId, templateId, type, category } = useParams<{
    groupId?: string;
    templateId?: string;
    appId?: string;
    category?: Templates.Category;
    type: GroupsType;
  }>();

  const { state } = useLocation();

  const { t } = useTranslation();

  const [isGroupLoaded, setIsGroupLoaded] = useState(false);

  const mockupsInitSize = useMemo(
    () => ({
      [SizePresetKeys.IphoneSmall]: {
        width: 427,
        height: 858
      },
      [SizePresetKeys.IphoneLarge]: {
        width: 403,
        height: 817
      }
    }),
    []
  );

  const {
    status,
    changedObjects,
    stories,
    selectedLayersGroupId,
    editorMode,
    scheduleLayersGroupId
  } = useGetStoriesParams(type);

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef(null);
  const currentGroup = useGetCurrentGroup(type, templateId ?? groupId, !!templateId);

  const selectedStoryId = selectedLayersGroupId
    ? stories[selectedLayersGroupId].activeLayerId
    : null;

  const storyEditorState = useStoryEditor();
  const storyEditorDispatch = useStoryEditorDispatch();
  const selectedWidgetIds = storyEditorState?.selectedWidgetsIds ?? [];
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale) ?? 'en';
  const onDefaultLocale = useIsDefaultLanguageChoosen();
  const shareStoryId = useSelector((store: RootState) => store.stories.editor.shareStoryId);
  const lastPublishedStoryId = useSelector(
    (store: RootState) => store.stories.editor.lastPublishedStoryId
  );

  const currentSizePreset = useCurrentSizePreset(currentGroup);
  const [selectedSizePreset, setSelectedSizePreset] = useState<SizePreset>(currentSizePreset);

  useEffect(() => {
    if (currentGroup && !isGroupLoaded && currentGroup.settings?.sizePreset) {
      setSelectedSizePreset(SIZE_PRESETS[currentGroup.settings.sizePreset]);
      setIsGroupLoaded(true);
    }
  }, [currentGroup]);

  useEffect(() => {
    if (currentGroup && currentGroup.settings?.sizePreset !== selectedSizePreset.title) {
      if (type === GroupsType.TEMPLATE) {
        const templateParams = {
          templateId,
          active: currentGroup.active,
          title: currentGroup.title,
          description: currentGroup.description,
          type: currentGroup.type,
          category: currentGroup.category,
          noNotification: true,
          settings: {
            ...currentGroup.settings,
            sizePreset: selectedSizePreset.title
          }
        };

        dispatch(fetchUpdateTemplate(templateParams));
      } else if (type === GroupsType.ONBOARDING) {
        const params = {
          appId,
          groupId,
          active: currentGroup.active,
          title: currentGroup.title,
          description: currentGroup.description,
          startTime: currentGroup.startDate,
          endTime: currentGroup.endDate,
          noNotification: true,
          settings: {
            ...currentGroup.settings,
            sizePreset: selectedSizePreset.title
          }
        };

        dispatch(fetchUpdateOnboarding(params));
      }
    }
  }, [type, selectedSizePreset, appId, groupId, templateId, dispatch]);

  useFetchStories({
    type,
    appId,
    groupId,
    templateId,
    selectedLayersGroupId:
      (state as { layersGroupId?: string })?.layersGroupId ?? selectedLayersGroupId
  });

  useSaveChanges({
    changedObjects,
    stories,
    appId,
    groupId,
    templateId
  });

  useLogicModeOff({
    appId,
    currentGroup,
    selectedLayersGroupId,
    selectedStoryId,
    type
  });

  useSaveLastPosition({
    appId,
    currentGroup,
    type
  });

  useEffect(() => {
    if ((state as { layersGroupId?: string })?.layersGroupId) {
      // @ts-ignore
      const newState = { ...state };
      delete newState.layersGroupId;
      window.history.replaceState(newState, '');
    }
  }, [state]);

  const activeLayer = useMemo(() => {
    if (selectedLayersGroupId && selectedStoryId) {
      return stories[selectedLayersGroupId].layers[selectedStoryId];
    }

    return null;
  }, [selectedLayersGroupId, selectedStoryId, stories]);

  const layerGroupsArr = Object.values(stories).sort((storyA: any, storyB: any) =>
    storyA.position < storyB.position ? -1 : 1
  );

  useEffect(() => {
    if (selectedStoryId !== storyEditorState?.story?.id) {
      storyEditorDispatch?.({
        type: StoryEditorAction.SET_STORY,
        payload: activeLayer
      });
    }
  }, [selectedStoryId]);

  const currentStoriesSlice = useCurrentStoriesSlice(type);
  const containerSize = useElementSize(containerRef);

  const handleToggleStory = useCallback(
    (story: Story) => () => {
      dispatch(currentStoriesSlice.actions.setSelectedLayersGroupId(story.layerData.layersGroupId));
      dispatch(currentStoriesSlice.actions.setEditorMode(EditorMode.DESIGN));

      if (storyEditorDispatch && selectedLayersGroupId) {
        storyEditorDispatch({
          type: StoryEditorAction.SET_STORY,
          payload: story
        });

        storyEditorDispatch({
          type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
          payload: []
        });
      }
    },
    [selectedLayersGroupId]
  );

  const isShowTitle = !(
    selectedStoryId &&
    (type === GroupsType.ONBOARDING || category === Templates.Category.ONBOARDING)
  );

  const currentCardTop =
    selectedSizePreset.title === SizePresetKeys.IphoneLarge ? INIT_LARGE_CARD_TOP : INIT_CARD_TOP;

  const containerPaddingTop = isShowTitle ? TOOGLE_HEIGHT : 0;

  const cardTop = getReducedValue(
    currentCardTop * (containerSize.height + containerPaddingTop),
    selectedSizePreset.height
  );

  const backgroundTopLarge = getReducedValue(
    INIT_LARGE_CARD_TOP * containerSize.height,
    SIZE_PRESETS[SizePresetKeys.IphoneLarge].height
  );

  const editorSize = useCardSize({
    containerRef,
    storyWidth: mockupsInitSize[selectedSizePreset.title].width,
    storyHeight: mockupsInitSize[selectedSizePreset.title].height,
    containerPaddingTop: !isShowTitle ? TOOGLE_HEIGHT : 0
  });

  const cardSize = useCardSize({
    containerRef,
    storyWidth: selectedSizePreset.width,
    storyHeight: selectedSizePreset.height,
    gap: cardTop * 2,
    containerPaddingTop: !isShowTitle ? TOOGLE_HEIGHT : 0
  });

  const backgroundSize = useCardSize({
    containerRef,
    storyWidth: selectedSizePreset.width,
    storyHeight: selectedSizePreset.height,
    gap: cardTop * 2,
    containerPaddingTop: !isShowTitle ? TOOGLE_HEIGHT : 0
  });

  const [keyPressed, setKeyPressed] = useState<string | null>(null);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === CMD_KEY_CODE || event.keyCode === CONTROL_KEY_CODE) {
        setKeyPressed('CONTROL');
      } else if (event.key === 'Alt') {
        setKeyPressed('ALT');
      } else if (event.key === 'Shift') {
        setKeyPressed('SHIFT');
      }

      const activeElement = document.activeElement?.tagName;

      if (keyPressed === 'CONTROL' && (activeElement === 'BODY' || activeElement === 'DIV')) {
        switch (event.code) {
          case 'KeyC':
            if (selectedStoryId && selectedLayersGroupId && selectedWidgetIds.length) {
              dispatch(
                currentStoriesSlice.actions.copyWidgets({
                  storyId: selectedStoryId,
                  widgetIds: selectedWidgetIds,
                  layersGroupId: selectedLayersGroupId
                })
              );
            }

            break;
          case 'KeyV':
            if (selectedStoryId && selectedLayersGroupId) {
              dispatch(
                currentStoriesSlice.actions.pasteWidgetsFromBuffer({
                  storyId: selectedStoryId,
                  layersGroupId: selectedLayersGroupId,
                  storyWidth: selectedSizePreset.width,
                  storyHeight: selectedSizePreset.height,
                  currentLocale
                })
              );
            }
            break;
        }
      } else if (
        (event.code === 'Backspace' || event.code === 'Delete') &&
        checkIsNotInput(event)
      ) {
        storyEditorDispatch?.({ type: StoryEditorAction.REMOVE_SELECTED_STORY_WIDGETS });
      }
    },
    [dispatch, keyPressed, selectedStoryId, selectedWidgetIds]
  );

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (
      event.keyCode === CMD_KEY_CODE ||
      event.keyCode === CONTROL_KEY_CODE ||
      event.key === 'Alt' ||
      event.key === 'Shift'
    ) {
      setKeyPressed(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  const handleEditorClick = useCallback(
    (e: any) => {
      if (
        e.nativeEvent?.target?.classList?.contains('StoryEditor') ||
        e.nativeEvent?.target?.classList?.contains('EditorStories') ||
        e.nativeEvent?.target?.classList?.contains('simplebar-content') ||
        e.nativeEvent?.target?.classList?.contains('EditorStories__titleContainer')
      ) {
        storyEditorDispatch?.({
          type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
          payload: []
        });
      }
    },
    [storyEditorDispatch]
  );

  useEffect(() => {
    dispatch(
      currentStoriesSlice.actions.setIsActiveLayerWithoutBackground(
        activeLayer?.background.type === BackgroundColorType.TRANSPARENT
      )
    );
  }, [activeLayer]);

  useEffect(() => {
    const storyId = shareStoryId || '';
    const layerId = selectedLayersGroupId || '';

    if (!!activeLayer && storyId && layerId && shareStoryId === lastPublishedStoryId) {
      const selectedLayer = layerId ? stories[layerId].layers[storyId] : null;

      if (selectedLayer?.layerData.shortDataId) {
        const shareLink = `${process.env.REACT_APP_SITE_URL}/share/${selectedLayer?.layerData.shortDataId}`;

        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.SHARE,
            shareLink
          })
        );

        dispatch(storiesSlice.actions.setShareStoryId(null));
        dispatch(currentStoriesSlice.actions.setLastPublishedStoryId(null));
      }
    }
  }, [
    activeLayer,
    currentStoriesSlice.actions,
    dispatch,
    lastPublishedStoryId,
    selectedLayersGroupId,
    shareStoryId,
    stories
  ]);

  const toogleSyncWidgetsSize = useCallback(
    (isActive: boolean) => {
      storyEditorState?.story &&
        storyEditorState.story.layerData.isWidgetsSizeSyncActive !== isActive &&
        storyEditorDispatch?.({
          type: StoryEditorAction.UPDATE_STORY,
          payload: {
            layerData: {
              ...storyEditorState.story.layerData,
              isWidgetsSizeSyncActive: isActive
            }
          }
        });
    },
    [storyEditorDispatch, storyEditorState?.story]
  );

  const getBackgroundBorderRadius = useCallback(
    (isFilled?: boolean) => {
      if (
        (type === GroupsType.GROUP ||
          (type === GroupsType.TEMPLATE && category === Templates.Category.STORIES)) &&
        !isFilled
      ) {
        return 4;
      }

      if (selectedSizePreset.title === SizePresetKeys.IphoneLarge) {
        return getReducedValue(
          BACKGROUND_BORDER_RADIUS * cardSize.width,
          SIZE_PRESETS[SizePresetKeys.IphoneLarge].width
        );
      }

      return 0;
    },
    [cardSize.width, category, selectedSizePreset.title, type]
  );

  const cardScale = useCardScale({
    storyWidth: cardSize.width,
    presetWidth: selectedSizePreset.width
  });

  const cardScaleMemo = useMemo(() => cardScale, [cardScale]);

  const hiddenBorderRadius = getReducedValue(
    HIDDEN_BORDER_RADIUS * cardSize.width,
    SIZE_PRESETS[currentSizePreset.title].width
  );

  const innerBorderRadius = getReducedValue(
    BACKGROUND_BORDER_RADIUS * cardSize.width,
    SIZE_PRESETS[currentSizePreset.title].width
  );

  const renderStory = useCallback(
    (story: Story) => {
      const isSelected = selectedLayersGroupId === story.layerData.layersGroupId;

      if (isSelected) {
        return (
          <StoryEditor
            backgroundBorderRadius={getBackgroundBorderRadius(story.background.isFilled)}
            backgroundSize={backgroundSize}
            backgroundTop={
              type === GroupsType.GROUP ||
                (type === GroupsType.TEMPLATE && category === Templates.Category.STORIES)
                ? backgroundTopLarge
                : undefined
            }
            cardSize={cardSize}
            cardTop={cardTop}
            currentSizePreset={selectedSizePreset.title}
            editorSize={editorSize}
            groupType={type}
            hiddenBorderRadius={hiddenBorderRadius}
            innerBorderRadius={innerBorderRadius}
            innerClassName={b('card', {
              selected: isSelected
            })}
            isAltPressed={keyPressed === 'ALT'}
            isHidden={story.storyData[currentLocale].is_hidden && !onDefaultLocale}
            isReadOnly={!isSelected || editorMode === EditorMode.LOGIC}
            isShiftPressed={keyPressed === 'SHIFT'}
            key={story.id}
            presetHeight={selectedSizePreset.height}
            presetWidth={selectedSizePreset.width}
            scale={cardScaleMemo}
            storyPreview={story}
            templateCategory={category}
            onChangeSizePreset={(title: SizePresetKeys, isDisableSizeSync: boolean) => {
              setSelectedSizePreset(SIZE_PRESETS[title]);
              toogleSyncWidgetsSize(isDisableSizeSync);
            }}
            onToggleStory={handleToggleStory(story)}
          />
        );
      }

      return (
        <StoryEditorPreview
          backgroundSize={backgroundSize}
          backgroundTop={cardTop}
          cardHeight={cardSize.height}
          cardTop={cardTop}
          cardWeight={cardSize.width}
          currentSizePreset={selectedSizePreset.title}
          innerClassName={b('card')}
          isHidden={story.storyData[currentLocale].is_hidden && !onDefaultLocale}
          isOnboarding={
            type === GroupsType.ONBOARDING || category === Templates.Category.ONBOARDING
          }
          presetHeight={selectedSizePreset.height}
          presetWidth={selectedSizePreset.width}
          scale={cardScaleMemo}
          story={story}
          onToggleStory={handleToggleStory(story)}
        />
      );
    },
    [
      type,
      cardSize.height,
      cardSize.width,
      currentLocale,
      onDefaultLocale,
      selectedStoryId,
      keyPressed,
      layerGroupsArr,
      editorSize,
      selectedSizePreset
    ]
  );

  const storiesCount = useMemo(() => layerGroupsArr.length, [layerGroupsArr.length]);

  if (status === 'pending' || status === 'idle') {
    return (
      <SkeletonTheme baseColor="#18182E" highlightColor="#0e0e2a">
        <section className={b({ skeleton: true })}>
          <div className={b('titleContainer')}>
            <Skeleton height={40} style={{ marginRight: 20, borderRadius: 12 }} width={138} />
            <Skeleton height={36} style={{ borderRadius: 12 }} width={84} />
          </div>

          <div className={b('storiesSkeleton')}>
            {Array.from(Array(4)).map((_, index) => (
              <div className={b('carousel-item')} key={`cardSkelet-${index}`}>
                <div
                  className={b('card', { skeleton: true })}
                  style={{
                    minWidth: 300,
                    width: '20vw',
                    height: '100%'
                  }}
                >
                  <Skeleton height="100%" width="100%" />
                </div>
              </div>
            ))}
          </div>
        </section>
      </SkeletonTheme>
    );
  }

  const handleSaveSchedule = (layersGroupId: string, time: ScheduleTimeType) => {
    if (!appId || !groupId) {
      return;
    }

    dispatch(
      fetchUpdateLayersGroupSchedule({
        appId,
        groupId,
        layersGroupId,
        time
      })
    );

    dispatch(currentStoriesSlice.actions.setScheduleLayersGroupId(null));
  };

  const handleCloseScheduleClick = () => {
    dispatch(currentStoriesSlice.actions.setScheduleLayersGroupId(null));
  };

  return (
    <>
      <section className={b()} onMouseDown={handleEditorClick}>
        {isShowTitle && (
          <div className={b('titleContainer')}>
            <p className={b('title')}>{currentGroup?.title}</p>
            <span className={b('counter')}>
              {storiesCount} {t('editor.stories')}
            </span>
          </div>
        )}

        <div className={b('scrolllContainer', { fullWidth: !isShowTitle })} ref={containerRef}>
          <SimpleBar
            className={b('storiesContainer', { noTitle: !isShowTitle })}
            ref={scrollRef}
            scrollableNodeProps={{
              className: b('stories-carousel')
            }}
          >
            {layerGroupsArr.map((story) => (
              <div className={b('carousel-item')} key={story.id}>
                {renderStory(story.layers[story.activeLayerId])}
              </div>
            ))}
            <div className={b('carousel-item')}>
              <div
                className={b('card')}
                style={{
                  width: cardSize.width,
                  height: cardSize.height,
                  top: cardTop
                }}
              >
                {onDefaultLocale ? (
                  <AddStory isEditorMode type={type} />
                ) : (
                  <>{!onDefaultLocale && <StoryCardMessage text={t('editor.noCreateStories')} />}</>
                )}
              </div>
            </div>
          </SimpleBar>
        </div>
      </section>

      {!!activeLayer && selectedLayersGroupId && scheduleLayersGroupId && (
        <StoryScheduleModal
          endTime={activeLayer.storyData[currentLocale].end_time}
          handleSaveSchedule={handleSaveSchedule}
          isOpen={!!scheduleLayersGroupId}
          layersGroupId={selectedLayersGroupId}
          startTime={activeLayer.storyData[currentLocale].start_time ?? ''}
          theme="dark"
          onClose={handleCloseScheduleClick}
        />
      )}

      {!!activeLayer &&
        groupId &&
        selectedLayersGroupId &&
        shareStoryId &&
        shareStoryId !== lastPublishedStoryId && (
          <StoryShareModal
            groupId={groupId}
            isOpen
            layerId={selectedLayersGroupId}
            storyId={shareStoryId}
            theme="dark"
            type={type}
            onClose={() => {
              dispatch(storiesSlice.actions.setShareStoryId(null));
              dispatch(currentStoriesSlice.actions.setLastPublishedStoryId(null));
            }}
          />
        )}
    </>
  );
};

export default EditorStories;
