import React, { memo, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { useCurrentStoriesType } from '@features/stories/hooks';
import { useParams } from 'react-router-dom';
import { GroupsType } from '@features';
import { Icon, IconTemplates } from '@components';
import {
  EditorEmojiTab,
  EditorSimpleTab,
  EditorTemplatesTab,
  EditorWidgetsTab
} from './_components';
import './EditorSidebar.scss';

const b = block('EditorSidebar');

export const EditorSidebar: React.FC = memo(() => {
  const [tab, setTab] = React.useState(0);
  const [showMain, setShowMain] = React.useState(true);

  const { type } = useParams<{
    type: GroupsType;
  }>();

  const currentStoriesType = useCurrentStoriesType(type);
  const openTab = useSelector((store: RootState) => store[currentStoriesType].editor.openTab);
  const isActiveLayerWithoutBackground = useSelector(
    (store: RootState) => store[currentStoriesType].editor.isActiveLayerWithoutBackground
  );

  const handleSetTab = (newIndex: number) => () => {
    if (newIndex === tab) {
      setShowMain(false);
    }

    if (!showMain) {
      setShowMain(true);
    }

    setTab(newIndex);
  };

  useEffect(() => {
    if (openTab && openTab.tab === 'templates') {
      setTab(3);
    }
  }, [openTab]);

  useEffect(() => {
    setShowMain(!isActiveLayerWithoutBackground);
  }, [isActiveLayerWithoutBackground]);

  const templates = useSelector((store: RootState) => store.templates);

  return (
    <div className={b()}>
      <nav className={b('navigation')}>
        <button className={b('link', { active: showMain && tab === 0 })} onClick={handleSetTab(0)}>
          <Icon className={b('icon').toString()} name="text-3" variant="editor" />
        </button>
        <button className={b('link', { active: showMain && tab === 1 })} onClick={handleSetTab(1)}>
          <Icon className={b('icon').toString()} name="lightning" variant="editor" />
        </button>
        <button className={b('link', { active: showMain && tab === 2 })} onClick={handleSetTab(2)}>
          <Icon className={b('icon').toString()} name="sticker" variant="editor" />
        </button>
        {templates.templates?.length > 0 && (
          <button
            className={b('link', { active: showMain && tab === 3 })}
            onClick={handleSetTab(3)}
          >
            <IconTemplates className={b('icon', { filled: true }).toString()} />
          </button>
        )}
      </nav>
      <div className={b('main', { show: showMain })}>
        <div className={b('tab', { current: tab === 0, index: `${tab}` })}>
          <EditorSimpleTab />
        </div>
        <div className={b('tab', { current: tab === 1, index: `${tab}` })}>
          <EditorWidgetsTab />
        </div>
        <div className={b('tab', { current: tab === 2, index: `${tab}` })}>
          <EditorEmojiTab />
        </div>
        {templates.templates?.length > 0 && (
          <div className={b('tab', { current: tab === 3, index: `${tab}` })}>
            <EditorTemplatesTab />
          </div>
        )}
      </div>
    </div>
  );
});
