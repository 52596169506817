import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import {
  StoryStatus,
  informSlice,
  COPY_WIDGET_DEVIATION,
  makeNewWidgetFromExist,
  convertStoryFromRaw,
  templateStoriesSlice,
  MessageTypes,
  appsManagerSlice,
  GroupsType
} from '@features';
import {
  BackgroundType,
  getHtmlTextLayout,
  getHtmlWidgetLayout,
  getWidgetImageParts,
  getAdaptedTextParams
} from '@modules';
import { RootState } from '@store';
import { nanoid } from 'nanoid';
import { BackgroundColorType, MediaType, WidgetObjectType, WidgetsTypes } from '@storysdk/react';
import { DateTime } from 'luxon';
import { asyncForEach, getStoriesByLayers, getStoriesFlattenedArr } from '@utils';
import i18n from '../../i18n/i18n';
import {
  StoriesState,
  Story,
  BackgroundBlob,
  GroupPositionType,
  ElementHorizontalAlign,
  ElementVerticalAlign,
  ChangedObjectsType,
  SaveStatus,
  EditorMode,
  StoryLayers,
  LayerData,
  WidgetElemetsSizes,
  ScheduleTimeType
} from './types';
import { WidgetsToImage } from './consts';

const initialState: StoriesState = {
  stories: {},
  editor: {
    widgetsBuffer: { storyId: null, widgets: [] },
    selectedLayersGroupId: null,
    selectedWidgetIds: [],
    group: null,
    changedObjects: [],
    isPickerOpen: false,
    keepRatio: false,
    editorMode: EditorMode.DESIGN,
    scheduleLayersGroupId: null,
    shareStoryId: null,
    lastPublishedStoryId: null
  },
  filter: 'all',
  status: 'idle',
  creatingStatus: 'created',
  savingStatus: 'saved'
};

export const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    reset: () => initialState,
    initStories(
      state,
      action: PayloadAction<{ stories: StoryLayers; selectedLayersGroupId?: string }>
    ) {
      let selectedLayersGroupId = null;

      if (action.payload.selectedLayersGroupId) {
        selectedLayersGroupId = action.payload.selectedLayersGroupId;
      } else {
        const stories = getStoriesFlattenedArr(action.payload.stories).sort(
          (a, b) => a.position - b.position
        );
        selectedLayersGroupId = stories.length ? stories[0].layerData.layersGroupId : null;
      }

      state.stories = action.payload.stories;
      state.editor = {
        widgetsBuffer: { storyId: null, widgets: [] },
        selectedLayersGroupId,
        selectedWidgetIds: [],
        group: null,
        changedObjects: [],
        isPickerOpen: false,
        keepRatio: false,
        editorMode: EditorMode.DESIGN,
        scheduleLayersGroupId: null,
        shareStoryId: null,
        lastPublishedStoryId: null
      };
      state.status = 'loaded';
      state.creatingStatus = 'created';
      state.savingStatus = 'saved';
    },
    setEditorMode(state, action: PayloadAction<EditorMode>) {
      state.editor.editorMode = action.payload;
    },
    setIsActiveLayerWithoutBackground(state, action: PayloadAction<boolean>) {
      state.editor.isActiveLayerWithoutBackground = action.payload;
    },
    updateStories(state, action: PayloadAction<{ layers: Story[]; layersGroupId: string }>) {
      if (state.stories[action.payload.layersGroupId]) {
        state.stories[action.payload.layersGroupId].layers = action.payload.layers.reduce(
          (a, story) => ({ ...a, [story.id]: story }),
          {}
        );
      }
    },
    updateStoryWithoutSaving(state, action: PayloadAction<Story>) {
      if (action.payload.layerData?.layersGroupId) {
        state.stories[action.payload.layerData.layersGroupId].layers[action.payload.id] =
          action.payload;
      }
    },
    updateStory(state, action: PayloadAction<Story>) {
      if (action.payload.layerData?.layersGroupId) {
        state.stories[action.payload.layerData.layersGroupId].layers[action.payload.id] = {
          ...action.payload,
          isHasUnpublishedChanges: true
        };
      }

      state.editor.changedObjects = [
        ...state.editor.changedObjects,
        {
          id: nanoid(),
          layersGroupId: action.payload.layerData.layersGroupId,
          storyId: action.payload.id,
          saveStatus: SaveStatus.NOT_SAVED
        }
      ];
    },
    updateStoryBackground(
      state,
      action: PayloadAction<{ background: BackgroundType; currentLocale?: string }>
    ) {
      const selectedLayersGroupId = state.editor.selectedLayersGroupId;
      const selectedStoryId = selectedLayersGroupId
        ? state.stories[selectedLayersGroupId].activeLayerId
        : null;

      if (selectedLayersGroupId && selectedStoryId) {
        state.stories[selectedLayersGroupId].layers[selectedStoryId].background =
          action.payload.background;

        state.editor.changedObjects = [
          ...state.editor.changedObjects,
          {
            id: nanoid(),
            layersGroupId: selectedLayersGroupId,
            storyId: selectedStoryId,
            saveStatus: SaveStatus.NOT_SAVED
          }
        ];
      }
    },
    setChangedObjects(state, action: PayloadAction<ChangedObjectsType[]>) {
      state.editor.changedObjects = action.payload;
    },
    setIsFirstLoad(state, action: PayloadAction<boolean>) {
      state.isFirstLoad = action.payload;
    },
    setScheduleLayersGroupId(state, action: PayloadAction<string | null>) {
      state.editor.scheduleLayersGroupId = action.payload;
    },
    setShareStoryId(state, action: PayloadAction<string | null>) {
      state.editor.shareStoryId = action.payload;
    },
    setLastPublishedStoryId(state, action: PayloadAction<string | null>) {
      state.editor.lastPublishedStoryId = action.payload;
    },
    setLoadingStatus(state, action: PayloadAction<StoriesState['status']>) {
      state.status = action.payload;
    },
    setSavingStatus(state, action: PayloadAction<StoriesState['savingStatus']>) {
      state.savingStatus = action.payload;
    },
    setFilter(state, action: PayloadAction<string>) {
      state.filter = action.payload;
    },
    addChangedObject(state, action: PayloadAction<ChangedObjectsType>) {
      state.editor.changedObjects = [...state.editor.changedObjects, action.payload];
    },
    deleteChangedObjectsByStoryId(state, action: PayloadAction<string>) {
      state.editor.changedObjects = state.editor.changedObjects.filter(
        (item) => item.storyId !== action.payload
      );
    },
    deleteChangedObjectsByWidgetId(state, action: PayloadAction<string>) {
      state.editor.changedObjects = state.editor.changedObjects.filter(
        (item) => item.widgetId !== action.payload
      );
    },
    deleteChangedObject(state, action: PayloadAction<string>) {
      state.editor.changedObjects = state.editor.changedObjects.filter(
        (item) => item.id !== action.payload
      );
    },
    setChangedObjectSavingStatus(state, action: PayloadAction<{ id: string; status: SaveStatus }>) {
      state.editor.changedObjects = state.editor.changedObjects.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, saveStatus: action.payload.status };
        }

        return item;
      });
    },
    setChangedObjectSavingStatusByLayersGroupId(
      state,
      action: PayloadAction<{ layersGroupId: string; status: SaveStatus }>
    ) {
      state.editor.changedObjects = state.editor.changedObjects.map((item) => {
        if (item.layersGroupId === action.payload.layersGroupId) {
          return { ...item, saveStatus: action.payload.status };
        }

        return item;
      });
    },
    setCreatingStatus(state, action: PayloadAction<StoriesState['creatingStatus']>) {
      state.creatingStatus = action.payload;
    },
    addStory(state, action: PayloadAction<{ story: Story; type?: GroupsType }>) {
      if (
        Object.keys(state.stories).length > 0 &&
        action.payload.type === GroupsType.GROUP &&
        !state.stories[action.payload.story.layerData.layersGroupId]
      ) {
        Object.values(state.stories).forEach((layersGroup) => {
          const newPosition = state.stories[layersGroup.id].position + 1;
          state.stories[layersGroup.id].position = newPosition;

          Object.values(state.stories[layersGroup.id].layers).forEach((layer) => {
            state.stories[layersGroup.id].layers[layer.id].position = newPosition;

            state.editor.changedObjects = [
              ...state.editor.changedObjects,
              {
                id: nanoid(),
                layersGroupId: layersGroup.id,
                storyId: layer.id,
                saveStatus: SaveStatus.NOT_SAVED
              }
            ];
          });
        });
      }

      if (!state.stories[action.payload.story.layerData.layersGroupId]) {
        state.stories[action.payload.story.layerData.layersGroupId] = {
          id: action.payload.story.layerData.layersGroupId,
          activeLayerId: action.payload.story.id,
          defaultLayerId: action.payload.story.id,
          position: action.payload.story.position,
          layers: {
            [action.payload.story.id]: action.payload.story
          }
        };
      } else {
        state.stories[action.payload.story.layerData.layersGroupId].layers[
          action.payload.story.id
        ] = action.payload.story;
      }

      state.creatingStatus = 'created';
    },
    removeStory(
      state,
      action: PayloadAction<{ layersGroupId: string; storyId: string; updatePositions?: boolean }>
    ) {
      if (Object.keys(state.stories[action.payload.layersGroupId].layers).length === 1) {
        let lastPosition = 0;
        let prevLayerIndex = 0;
        let nextLayerIndex = -1;
        const storiesSorted = Object.values(state.stories).sort((a, b) => a.position - b.position);

        storiesSorted.forEach((layersGroup, index) => {
          if (layersGroup.id === action.payload.layersGroupId) {
            prevLayerIndex = index - 1;

            if (storiesSorted[index + 1]) {
              nextLayerIndex = index + 1;
            }
          }
          if (layersGroup.position > lastPosition) {
            lastPosition = layersGroup.position;
          }
        });

        const isLastPosition =
          state.stories[action.payload.layersGroupId].position === lastPosition;

        if (prevLayerIndex > -1) {
          state.editor.selectedLayersGroupId = storiesSorted[prevLayerIndex].id;
        } else if (nextLayerIndex > -1) {
          state.editor.selectedLayersGroupId = storiesSorted[nextLayerIndex].id;
        } else {
          state.editor.selectedLayersGroupId = null;
        }

        delete state.stories[action.payload.layersGroupId];

        if (!isLastPosition) {
          Object.values(state.stories)
            .sort((a, b) => a.position - b.position)
            .forEach((layersGroup, index) => {
              state.stories[layersGroup.id].position = index + 1;

              Object.values(state.stories[layersGroup.id].layers).forEach((layer) => {
                state.stories[layersGroup.id].layers[layer.id].position = index + 1;

                state.editor.changedObjects = [
                  ...state.editor.changedObjects,
                  {
                    id: nanoid(),
                    layersGroupId: layersGroup.id,
                    storyId: layer.id,
                    saveStatus: SaveStatus.NOT_SAVED
                  }
                ];
              });
            });
        }
      } else {
        const layersGroups = state.stories[action.payload.layersGroupId];
        const layers = Object.values(layersGroups.layers).sort(
          (layerA, layerB) => layerA.layerData.positionInGroup - layerB.layerData.positionInGroup
        );

        if (action.payload.updatePositions) {
          const newLayers = layers.filter(({ id }) => id !== action.payload.storyId);
          newLayers.forEach((item, index) => {
            state.stories[action.payload.layersGroupId].layers[item.id].layerData.positionInGroup =
              index + 1;

            state.editor.changedObjects = [
              ...state.editor.changedObjects,
              {
                id: nanoid(),
                layersGroupId: item.layerData.layersGroupId,
                storyId: item.id,
                saveStatus: SaveStatus.NOT_SAVED
              }
            ];
          });
        }

        if (layersGroups.activeLayerId === action.payload.storyId) {
          const nextLayer = layers.find(({ id }) => id !== action.payload.storyId);

          if (nextLayer) {
            state.stories[action.payload.layersGroupId].activeLayerId = nextLayer.id;
          }
        }

        delete state.stories[action.payload.layersGroupId].layers[action.payload.storyId];
      }
    },
    setSelectedLayersGroupId(state, action: PayloadAction<string | null>) {
      state.editor.selectedLayersGroupId = action.payload;
    },
    setActiveLayerId(state, action: PayloadAction<string>) {
      const selectedLayersGroupId = state.editor.selectedLayersGroupId;

      if (selectedLayersGroupId) {
        state.stories[selectedLayersGroupId].activeLayerId = action.payload;

        for (const layerId in state.stories[selectedLayersGroupId].layers) {
          if (
            layerId !== action.payload &&
            state.stories[selectedLayersGroupId].layers[layerId]?.layerData.isActiveLayerInEditor
          ) {
            state.stories[selectedLayersGroupId].layers[
              layerId
            ].layerData.isActiveLayerInEditor = false;
          }
        }

        state.stories[selectedLayersGroupId].layers[
          action.payload
        ].layerData.isActiveLayerInEditor = true;
      }
    },
    setDefaultLayerId(state, action: PayloadAction<string>) {
      const selectedLayersGroupId = state.editor.selectedLayersGroupId;

      if (selectedLayersGroupId) {
        state.stories[selectedLayersGroupId].defaultLayerId = action.payload;

        for (const layerId in state.stories[selectedLayersGroupId].layers) {
          if (
            layerId !== action.payload &&
            state.stories[selectedLayersGroupId].layers[layerId]?.layerData.isDefaultLayer
          ) {
            state.stories[selectedLayersGroupId].layers[layerId].layerData.isDefaultLayer = false;

            state.editor.changedObjects = [
              ...state.editor.changedObjects,
              {
                id: nanoid(),
                layersGroupId: selectedLayersGroupId,
                storyId: layerId,
                saveStatus: SaveStatus.NOT_SAVED
              }
            ];
          }
        }

        state.stories[selectedLayersGroupId].layers[action.payload].layerData.isDefaultLayer = true;

        state.editor.changedObjects = [
          ...state.editor.changedObjects,
          {
            id: nanoid(),
            layersGroupId: selectedLayersGroupId,
            storyId: action.payload,
            saveStatus: SaveStatus.NOT_SAVED
          }
        ];
      }
    },
    setPosition(state, { payload }: PayloadAction<number>) {
      const selectedLayersGroupId = state.editor.selectedLayersGroupId;
      const selectedStoryId = selectedLayersGroupId
        ? state.stories[selectedLayersGroupId].activeLayerId
        : null;

      if (selectedLayersGroupId && selectedStoryId) {
        const stories = getStoriesFlattenedArr(state.stories);

        const duplicatedPositionLayers = stories.filter(({ position }) => position === payload);

        duplicatedPositionLayers.forEach((duplicatedPositionLayer) => {
          state.stories[duplicatedPositionLayer.layerData.layersGroupId].position =
            state.stories[selectedLayersGroupId].layers[selectedStoryId].position;
          state.stories[duplicatedPositionLayer.layerData.layersGroupId].layers[
            duplicatedPositionLayer.id
          ].position = state.stories[selectedLayersGroupId].layers[selectedStoryId].position;

          state.editor.changedObjects = [
            ...state.editor.changedObjects,
            {
              id: nanoid(),
              layersGroupId: duplicatedPositionLayer.layerData.layersGroupId,
              storyId: duplicatedPositionLayer.id,
              saveStatus: SaveStatus.NOT_SAVED
            }
          ];
        });

        for (const layerId in state.stories[selectedLayersGroupId].layers) {
          if (state.stories[selectedLayersGroupId].layers[layerId]) {
            state.stories[selectedLayersGroupId].position = payload;
            state.stories[selectedLayersGroupId].layers[layerId].position = payload;

            state.editor.changedObjects = [
              ...state.editor.changedObjects,
              {
                id: nanoid(),
                layersGroupId: selectedLayersGroupId,
                storyId: layerId,
                saveStatus: SaveStatus.NOT_SAVED
              }
            ];
          }
        }
      }
    },
    setVisibility(
      state,
      action: PayloadAction<{
        id: string;
        layersGroupId: string;
        isHidden: boolean;
        currentLocale: string;
      }>
    ) {
      state.stories[action.payload.layersGroupId].layers[action.payload.id].storyData[
        action.payload.currentLocale
      ].is_hidden = action.payload.isHidden;
    },
    setIsSyncDesignActive(
      state,
      action: PayloadAction<{
        id: string;
        layersGroupId: string;
        isActive: boolean;
        currentLocale: string;
      }>
    ) {
      state.stories[action.payload.layersGroupId].layers[action.payload.id].storyData[
        action.payload.currentLocale
      ].is_sync_design_active = action.payload.isActive;
    },
    setLayerData(
      state,
      action: PayloadAction<{
        id: string;
        layersGroupId: string;
        layerData: LayerData;
      }>
    ) {
      state.stories[action.payload.layersGroupId].layers[action.payload.id].layerData =
        action.payload.layerData;
    },
    setStatus(
      state,
      action: PayloadAction<{
        id: string;
        status: StoryStatus;
        currentLocale: string;
        layersGroupId: string;
      }>
    ) {
      if (
        state.stories[action.payload.layersGroupId].layers[action.payload.id].storyData?.[
        action.payload.currentLocale
        ]
      ) {
        state.stories[action.payload.layersGroupId].layers[action.payload.id].storyData[
          action.payload.currentLocale
        ].status = action.payload.status;

        if (action.payload.status === StoryStatus.ACTIVE) {
          state.stories[action.payload.layersGroupId].layers[
            action.payload.id
          ].isHasUnpublishedChanges = false;

          state.stories[action.payload.layersGroupId].layers[action.payload.id].storyData[
            action.payload.currentLocale
          ].history = {
            ...state.stories[action.payload.layersGroupId].layers[action.payload.id].storyData[
              action.payload.currentLocale
            ].history,
            story_data: null,
            published_at: DateTime.now().toISO()
          };
        }
      }
    },
    setStatusToAllStories(
      state,
      action: PayloadAction<{
        status: StoryStatus;
        currentLocale: string;
      }>
    ) {
      const stories = state.stories;
      const currentLocale = action.payload.currentLocale;

      for (const layersGroupId in stories) {
        if (Object.prototype.hasOwnProperty.call(stories, layersGroupId)) {
          for (const storyId in stories[layersGroupId].layers) {
            if (Object.prototype.hasOwnProperty.call(stories[layersGroupId].layers, storyId)) {
              state.stories[layersGroupId].layers[storyId].storyData[currentLocale].status =
                action.payload.status;

              if (action.payload.status === StoryStatus.ACTIVE) {
                state.stories[layersGroupId].layers[storyId].isHasUnpublishedChanges = false;

                state.stories[layersGroupId].layers[storyId].storyData[
                  action.payload.currentLocale
                ].history = {
                  ...state.stories[layersGroupId].layers[storyId].storyData[
                    action.payload.currentLocale
                  ].history,
                  story_data: null,
                  published_at: DateTime.now().toISO()
                };
              }
            }
          }
        }
      }
    },
    setScheduleTime(
      state,
      action: PayloadAction<{
        id: string;
        time: ScheduleTimeType;
        currentLocale: string;
        layersGroupId: string;
      }>
    ) {
      if (state.stories[action.payload.layersGroupId].layers[action.payload.id].storyData) {
        state.stories[action.payload.layersGroupId].layers[action.payload.id].storyData[
          action.payload.currentLocale
        ] = {
          ...state.stories[action.payload.layersGroupId].layers[action.payload.id].storyData[
          action.payload.currentLocale
          ],
          start_time: action.payload.time.startTime,
          end_time: action.payload.time.endTime
        };
      }
    },
    setBackground(
      state,
      action: PayloadAction<{
        storyId?: string;
        layersGroupId?: string;
        background: BackgroundType;
        currentLocale: string | null;
      }>
    ) {
      const selectedLayersGroupId =
        action.payload.layersGroupId ?? state.editor.selectedLayersGroupId;

      if (selectedLayersGroupId) {
        const selectedStoryId =
          action.payload.storyId ?? state.stories[selectedLayersGroupId].activeLayerId;

        state.stories[selectedLayersGroupId].layers[selectedStoryId].background = {
          ...state.stories[selectedLayersGroupId].layers[selectedStoryId].background,
          ...action.payload.background
        };

        if (action.payload.currentLocale) {
          const historyStoryData =
            state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
              action.payload.currentLocale
            ].history.story_data;

          if (historyStoryData) {
            state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
              action.payload.currentLocale
            ].history = {
              ...state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
                action.payload.currentLocale
              ].history,
              story_data: {
                ...historyStoryData,
                background: {
                  ...historyStoryData.background,
                  ...action.payload.background
                }
              }
            };
          }
        }

        state.stories[selectedLayersGroupId].layers[selectedStoryId].isHasUnpublishedChanges = true;

        state.editor.changedObjects = [
          ...state.editor.changedObjects,
          {
            id: nanoid(),
            layersGroupId: selectedLayersGroupId,
            storyId: selectedStoryId,
            saveStatus: SaveStatus.NOT_SAVED
          }
        ];
      }
    },
    setBackgroundFilled(
      state,
      action: PayloadAction<{ id: string; layersGroupId: string; isFilled: boolean }>
    ) {
      state.stories[action.payload.layersGroupId].layers[action.payload.id].background.isFilled =
        action.payload.isFilled;

      state.editor.changedObjects = [
        ...state.editor.changedObjects,
        {
          id: nanoid(),
          layersGroupId: action.payload.layersGroupId,
          storyId: action.payload.id,
          saveStatus: SaveStatus.NOT_SAVED
        }
      ];
    },
    updateWidgetsBuffer(state, action: PayloadAction<any>) {
      state.editor.widgetsBuffer = action.payload;
    },
    copyWidgets(
      state,
      action: PayloadAction<{ storyId: string; layersGroupId: string; widgetIds: string[] }>
    ) {
      const selectedStoryId = action.payload.storyId;
      const selectedWidgetIds = action.payload.widgetIds;
      const selectedLayersGroupId = action.payload.layersGroupId;

      if (!selectedWidgetIds.length) {
        return;
      }

      const currentWidgets = state.stories[selectedLayersGroupId].layers[selectedStoryId].widgets
        .filter((widget: WidgetObjectType) => selectedWidgetIds.includes(widget.id))
        .map((item: WidgetObjectType) => ({ ...item, id: `W${nanoid()}` }));

      if (currentWidgets.length) {
        state.editor.widgetsBuffer = { storyId: selectedStoryId, widgets: currentWidgets };
      }
    },
    pasteWidgetsFromBuffer(
      state,
      action: PayloadAction<{
        storyId: string;
        layersGroupId: string;
        storyWidth: number;
        storyHeight: number;
        currentLocale: string | null;
      }>
    ) {
      const selectedStoryId = action.payload.storyId;
      const selectedLayersGroupId = action.payload.layersGroupId;

      if (state.editor.widgetsBuffer.widgets.length) {
        const copyDeviation =
          selectedStoryId === state.editor.widgetsBuffer.storyId ? COPY_WIDGET_DEVIATION : 0;

        const newWidgets = [...state.editor.widgetsBuffer.widgets].map((item) =>
          makeNewWidgetFromExist({
            widget: item,
            copyDeviation,
            storyWidth: action.payload.storyWidth,
            storyHeight: action.payload.storyHeight
          })
        );

        state.stories[selectedLayersGroupId].layers[selectedStoryId].widgets = state.stories[
          selectedLayersGroupId
        ].layers[selectedStoryId].widgets.concat(newWidgets);

        if (action.payload.currentLocale) {
          const historyStoryData =
            state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
              action.payload.currentLocale
            ].history.story_data;

          if (historyStoryData) {
            state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
              action.payload.currentLocale
            ].history = {
              ...state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
                action.payload.currentLocale
              ].history,
              story_data: {
                ...historyStoryData,
                widgets: historyStoryData.widgets.concat(newWidgets)
              }
            };
          }
        }
      }
    },
    addStoryWidget(
      state,
      action: PayloadAction<{
        currentLocale?: string | null;
        widget: WidgetObjectType;
        preventSave?: boolean;
      }>
    ) {
      const selectedLayersGroupId = state.editor.selectedLayersGroupId;
      const selectedStoryId = selectedLayersGroupId
        ? state.stories[selectedLayersGroupId].activeLayerId
        : null;

      if (selectedLayersGroupId && selectedStoryId) {
        state.stories[selectedLayersGroupId].layers[selectedStoryId].isHasUnpublishedChanges = true;
        state.stories[selectedLayersGroupId].layers[selectedStoryId].widgets?.push(
          action.payload.widget
        );

        if (action.payload.currentLocale) {
          const historyStoryData =
            state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
              action.payload.currentLocale
            ].history.story_data;

          if (historyStoryData) {
            state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
              action.payload.currentLocale
            ].history = {
              ...state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
                action.payload.currentLocale
              ].history,
              story_data: {
                ...historyStoryData,
                widgets: [...historyStoryData.widgets, action.payload.widget]
              }
            };
          }
        }

        if (!action.payload.preventSave) {
          state.editor.changedObjects = [
            ...state.editor.changedObjects,
            {
              id: nanoid(),
              storyId: selectedStoryId,
              layersGroupId: selectedLayersGroupId,
              widgetId: WidgetsToImage.includes(action.payload.widget.content.type)
                ? action.payload.widget.id
                : undefined,
              saveStatus: SaveStatus.NOT_SAVED
            }
          ];
        }
      }
    },
    addStoryWidgets(state, action: PayloadAction<WidgetObjectType[]>) {
      const selectedLayersGroupId = state.editor.selectedLayersGroupId;
      const selectedStoryId = selectedLayersGroupId
        ? state.stories[selectedLayersGroupId].activeLayerId
        : null;

      if (selectedLayersGroupId && selectedStoryId) {
        state.stories[selectedLayersGroupId].layers[selectedStoryId].widgets = state.stories[
          selectedLayersGroupId
        ].layers[selectedStoryId].widgets.concat(action.payload);
      }
    },
    removeStoryWidgets(state) {
      const selectedLayersGroupId = state.editor.selectedLayersGroupId;
      const selectedStoryId = selectedLayersGroupId
        ? state.stories[selectedLayersGroupId].activeLayerId
        : null;
      const selectedWidgetIds = state.editor.selectedWidgetIds;

      if (!(selectedStoryId && selectedLayersGroupId && selectedWidgetIds.length)) {
        return;
      }

      state.stories[selectedLayersGroupId].layers[selectedStoryId].widgets = state.stories[
        selectedLayersGroupId
      ].layers[selectedStoryId].widgets.filter(({ id }) => !selectedWidgetIds.includes(id));

      state.editor.selectedWidgetIds = state.editor.selectedWidgetIds.filter(
        (id) => !selectedWidgetIds.includes(id)
      );

      state.editor.changedObjects = [
        ...state.editor.changedObjects,
        {
          id: nanoid(),
          storyId: selectedStoryId,
          layersGroupId: selectedLayersGroupId,
          saveStatus: SaveStatus.NOT_SAVED
        }
      ];
    },
    updateStoryWidgets(state, action: PayloadAction<WidgetObjectType[]>) {
      const selectedLayersGroupId = state.editor.selectedLayersGroupId;
      const selectedStoryId = selectedLayersGroupId
        ? state.stories[selectedLayersGroupId].activeLayerId
        : null;

      if (selectedLayersGroupId && selectedStoryId) {
        state.stories[selectedLayersGroupId].layers[selectedStoryId].widgets = action.payload;
      }
    },
    updateStoryWidget(
      state,
      action: PayloadAction<{
        widgetId: string;
        widget: WidgetObjectType;
        syncToLocales?: boolean;
        needToSaveImage?: boolean;
        storyId?: string;
        currentLocale: string | null;
        layersGroupId?: string;
        isHasUnpublishedChanges?: boolean;
      }>
    ) {
      const selectedLayersGroupId =
        action.payload.layersGroupId ?? state.editor.selectedLayersGroupId;
      let selectedStoryId = selectedLayersGroupId
        ? state.stories[selectedLayersGroupId].activeLayerId
        : null;

      if (action.payload.storyId) {
        selectedStoryId = action.payload.storyId;
      }

      if (selectedLayersGroupId && selectedStoryId) {
        state.stories[selectedLayersGroupId].layers[selectedStoryId].isHasUnpublishedChanges =
          action.payload.isHasUnpublishedChanges;
        state.stories[selectedLayersGroupId].layers[selectedStoryId].widgets = state.stories[
          selectedLayersGroupId
        ].layers[selectedStoryId].widgets.map((widget) => {
          if (widget.id === action.payload.widgetId) {
            return {
              ...widget,
              ...action.payload.widget
            };
          }

          return widget;
        });

        if (action.payload.currentLocale) {
          const historyStoryData =
            state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
              action.payload.currentLocale
            ].history.story_data;

          if (historyStoryData) {
            state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
              action.payload.currentLocale
            ].history = {
              ...state.stories[selectedLayersGroupId].layers[selectedStoryId].storyData[
                action.payload.currentLocale
              ].history,
              story_data: {
                ...historyStoryData,
                widgets: historyStoryData.widgets.map((widget: WidgetObjectType) => {
                  if (widget.id === action.payload.widgetId) {
                    return {
                      ...widget,
                      ...action.payload.widget
                    };
                  }

                  return widget;
                })
              }
            };
          }
        }

        state.editor.changedObjects = [
          ...state.editor.changedObjects,
          {
            id: nanoid(),
            storyId: selectedStoryId,
            layersGroupId: selectedLayersGroupId,
            syncToLocales: action.payload.syncToLocales,
            widgetId: action.payload.needToSaveImage ? action.payload.widgetId : undefined,
            saveStatus: SaveStatus.NOT_SAVED
          }
        ];
      }
    },
    updateStoryWidgetContentParams(
      state,
      action: PayloadAction<{
        widgetId: string;
        params: any;
        needToSaveImage?: boolean;
        isHasUnpublishedChanges?: boolean;
      }>
    ) {
      const selectedLayersGroupId = state.editor.selectedLayersGroupId;
      const selectedStoryId = selectedLayersGroupId
        ? state.stories[selectedLayersGroupId].activeLayerId
        : null;

      if (selectedLayersGroupId && selectedStoryId) {
        state.stories[selectedLayersGroupId].layers[selectedStoryId].isHasUnpublishedChanges =
          action.payload.isHasUnpublishedChanges;

        state.stories[selectedLayersGroupId].layers[selectedStoryId].widgets = state.stories[
          selectedLayersGroupId
        ].layers[selectedStoryId].widgets.map((widget) => {
          if (widget.id === action.payload.widgetId) {
            return {
              ...widget,
              content: {
                ...widget.content,
                params: {
                  ...widget.content.params,
                  ...action.payload.params
                }
              }
            };
          }

          return widget;
        });

        state.editor.changedObjects = [
          ...state.editor.changedObjects,
          {
            id: nanoid(),
            storyId: selectedStoryId,
            layersGroupId: selectedLayersGroupId,
            widgetId: action.payload.needToSaveImage ? action.payload.widgetId : undefined,
            saveStatus: SaveStatus.NOT_SAVED
          }
        ];
      }
    },
    setSelectedStoryWidgetIds(state, action: PayloadAction<string[]>) {
      state.editor.selectedWidgetIds = action.payload;
    },
    setSelectedGroup(state, action: PayloadAction<GroupPositionType | null>) {
      state.editor.group = action.payload;
    },
    setSelectedGroupAlign(
      state,
      action: PayloadAction<ElementHorizontalAlign | ElementVerticalAlign | null>
    ) {
      if (state.editor.group) {
        state.editor.group = {
          ...state.editor.group,
          lastAlign: action.payload
        };
      }
    },
    addSelectedStoryWidgetId(state, action: PayloadAction<string>) {
      state.editor.selectedWidgetIds = [...state.editor.selectedWidgetIds, action.payload];
    },
    deleteSelectedStoryWidgetId(state, action: PayloadAction<string>) {
      state.editor.selectedWidgetIds = state.editor.selectedWidgetIds.filter(
        (id) => id !== action.payload
      );
    },
    setIsPickerOpen(state, action: PayloadAction<boolean>) {
      state.editor.isPickerOpen = action.payload;
    },
    setPickerStoryOpen(state, action: PayloadAction<BackgroundColorType | undefined>) {
      state.editor.pickerStoryOpen = action.payload;
    },
    setOpenTab(state, action: PayloadAction<StoriesState['editor']['openTab']>) {
      state.editor.openTab = action.payload;
    },
    setKeepRatio(state, action: PayloadAction<boolean>) {
      state.editor.keepRatio = action.payload;
    }
  }
});

export const fetchGetStories = createAsyncThunk(
  'stories/fetchGetStories',
  async (
    params: { appId: string; groupId: string; selectedLayersGroupId?: string },
    { dispatch, getState }
  ) => {
    dispatch(storiesSlice.actions.setLoadingStatus('pending'));

    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;

    const stories = await API.stories.getList(params);

    const { data, error } = stories.data;

    if (data && !error && currentLocale) {
      const rawStories = data.map((item: any) => convertStoryFromRaw(item, currentLocale, 'exist'));
      const storiesByLayers = getStoriesByLayers(rawStories);

      dispatch(
        storiesSlice.actions.initStories({
          stories: storiesByLayers,
          selectedLayersGroupId:
            params.selectedLayersGroupId && storiesByLayers[params.selectedLayersGroupId]
              ? params.selectedLayersGroupId
              : undefined
        })
      );
    }
  }
);

export const fetchUpdateStoryBackground = createAsyncThunk(
  'stories/fetchUpdateStoryBackground',
  async (
    params: {
      storyId: string;
      layersGroupId: string;
      background: BackgroundBlob;
      modalProgressId?: string;
      onUploadProgress: (value: number, modalProgressId?: string) => void;
    },
    { dispatch, getState }
  ) => {
    const { appManager } = getState() as RootState;

    const currentLocale = appManager.currentLocale || appManager.current?.appLocalization.default;

    const storageData = await API.storage.create({
      file: params.background.value,
      onUploadProgress: params.onUploadProgress,
      modalProgressId: params.modalProgressId
    });

    if (storageData?.data && !storageData.data.error) {
      dispatch(
        storiesSlice.actions.setBackground({
          storyId: params.storyId,
          layersGroupId: params.layersGroupId,
          currentLocale,
          background: {
            type: params.background.type,
            value: storageData.data.data.file_url,
            fileId: storageData.data.data.id,
            metadata: params.background.metadata
          }
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.files.uploadeError')
        })
      );
    }
  }
);

export const fetchUpdateStorySyncDesignActive = createAsyncThunk<
  void,
  { appId: string; groupId: string; storyId: string; layersGroupId: string; isActive: boolean },
  { state: RootState }
>('stories/fetchUpdateStorySyncDesignActive', async (params, { dispatch, getState }) => {
  const { stories, appManager } = getState();

  const defaultLocale = appManager.current?.appLocalization.default;
  const currentLocale = appManager.currentLocale ?? defaultLocale;

  const locales = appManager.current?.appLocalization.languages.map((lang: any) => lang.shortName);
  const story = stories.stories[params.layersGroupId].layers[params.storyId];

  const storyData = {
    ...story,
    storyData: {
      ...story.storyData,
      [currentLocale]: {
        ...story.storyData?.[currentLocale],
        is_sync_design_active: params.isActive
      }
    }
  };

  const { data } = await API.stories.update({
    ...params,
    story: storyData,
    currentLocale,
    defaultLocale,
    locales
  });

  if (data.data && !data.error) {
    dispatch(
      storiesSlice.actions.setIsSyncDesignActive({
        id: params.storyId,
        layersGroupId: params.layersGroupId,
        isActive: params.isActive,
        currentLocale
      })
    );
  } else {
    dispatch(
      informSlice.actions.addMessage({
        type: MessageTypes.ERROR,
        text: i18n.t('notification.stories.updateError')
      })
    );
  }
});

export const fetchChangeStoriesLayerVisibility = createAsyncThunk<
  void,
  { appId: string; groupId: string; layersGroupId: string; isHidden: boolean },
  { state: RootState }
>('stories/fetchUpdateLayersGroupStatus', async (params, { dispatch, getState }) => {
  const { stories, appManager } = getState();

  const defaultLocale = appManager.current?.appLocalization.default;
  const currentLocale = appManager.currentLocale ?? defaultLocale;

  const locales = appManager.current?.appLocalization.languages.map((lang: any) => lang.shortName);
  const layers = Object.values(stories.stories[params.layersGroupId].layers);

  let isSuccessful = false;

  await asyncForEach(layers, async (story: Story) => {
    const storyData = {
      ...story,
      storyData: {
        ...story.storyData,
        [currentLocale]: {
          ...story.storyData?.[currentLocale],
          widgets: story.widgets,
          is_hidden: params.isHidden
        }
      }
    };

    const { data } = await API.stories.update({
      appId: params.appId,
      groupId: params.groupId,
      story: storyData,
      currentLocale,
      defaultLocale,
      locales
    });

    if (data.data && !data.error) {
      isSuccessful = true;
      dispatch(
        storiesSlice.actions.setVisibility({
          id: story.id,
          layersGroupId: params.layersGroupId,
          isHidden: params.isHidden,
          currentLocale
        })
      );
    }
  });

  if (isSuccessful) {
    if (!params.isHidden) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.stories.shown')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.stories.hidden')
        })
      );
    }
  } else {
    dispatch(
      informSlice.actions.addMessage({
        type: MessageTypes.ERROR,
        text: i18n.t('notification.stories.updateError')
      })
    );
  }
});

export const fetchChangeStoryVisibility = createAsyncThunk<
  void,
  { appId: string; groupId: string; storyId: string; layersGroupId: string; isHidden: boolean },
  { state: RootState }
>('stories/fetchChangeStoryVisibility', async (params, { dispatch, getState }) => {
  const { stories, appManager } = getState();

  const defaultLocale = appManager.current?.appLocalization.default;
  const currentLocale = appManager.currentLocale ?? defaultLocale;

  const locales = appManager.current?.appLocalization.languages.map((lang: any) => lang.shortName);
  const story = stories.stories[params.layersGroupId].layers[params.storyId];

  const storyData = {
    ...story,
    storyData: {
      ...story.storyData,
      [currentLocale]: {
        ...story.storyData?.[currentLocale],
        widgets: story.widgets,
        is_hidden: params.isHidden
      }
    }
  };

  const { data } = await API.stories.update({
    appId: params.appId,
    groupId: params.groupId,
    story: storyData,
    currentLocale,
    defaultLocale,
    locales
  });

  if (data.data && !data.error) {
    dispatch(
      storiesSlice.actions.setVisibility({
        id: params.storyId,
        layersGroupId: params.layersGroupId,
        isHidden: params.isHidden,
        currentLocale
      })
    );

    if (!params.isHidden) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.stories.shown')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.stories.hidden')
        })
      );
    }
  } else {
    dispatch(
      informSlice.actions.addMessage({
        type: MessageTypes.ERROR,
        text: i18n.t('notification.stories.updateError')
      })
    );
  }
});

export const fetchUpdateStoryWidgetBackground = createAsyncThunk(
  'stories/fetchUpdateStoryWidgetBackground',
  async (
    params: {
      widgetId: string;
      widgetType: WidgetsTypes;
      background: BackgroundBlob;
      modalProgressId?: string;
      onUploadProgress?: (value: number, modalProgressId?: string) => void;
      mediaType?: MediaType;
    },
    { dispatch }
  ) => {
    let storageData;

    if (params.background) {
      storageData = await API.storage.create({
        file: params.background.value,
        onUploadProgress: params.onUploadProgress,
        modalProgressId: params.modalProgressId
      });
    }

    if (storageData && storageData.data && !storageData.data.error) {
      let updateParams;

      if (params.mediaType === MediaType.IMAGE) {
        updateParams = {
          imageUrl: storageData.data.data.file_url,
          fileId: storageData.data.data.id
        };
      } else if (params.mediaType === MediaType.VIDEO) {
        updateParams = {
          videoUrl: storageData.data.data.file_url,
          fileId: storageData.data.data.id
        };
      } else {
        updateParams = {
          fillColor: {
            type: params.background.type,
            value: storageData.data.data.file_url,
            fileId: storageData.data.data.id,
            metadata: params.background.metadata
          }
        };
      }

      dispatch(
        storiesSlice.actions.updateStoryWidgetContentParams({
          widgetId: params.widgetId,
          params: updateParams,
          needToSaveImage: WidgetsToImage.includes(params.widgetType)
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.files.uploadeError')
        })
      );
    }
  }
);

export const fetchCreateStory = createAsyncThunk(
  'stories/fetchCreateStory',
  async (
    params: {
      appId: string;
      groupId: string;
      type?: GroupsType;
      story?: Story;
      isEditor?: boolean;
    },
    { dispatch, getState }
  ) => {
    dispatch(storiesSlice.actions.setCreatingStatus('creating'));

    const state = getState() as RootState;
    const appLangs = state.appManager.current?.appLocalization.languages;
    const storiesCount = state.appManager.current?.storiesCount ?? 0;
    const currentLocale =
      state.appManager.currentLocale || state.appManager.current?.appLocalization.default;
    const defaultLocale = state.appManager.current?.appLocalization.default;
    const locales = state.appManager.current?.appLocalization.languages.map(
      (lang: any) => lang.shortName
    );

    if (!appLangs) {
      return;
    }

    const { data, error } = await (
      await API.stories.create(
        params,
        appLangs.map((item: any) => item.shortName),
        defaultLocale
      )
    ).data;

    if (data && !error) {
      const shareData = {
        appToken: state.appManager.current?.sdkToken,
        groupId: params.groupId,
        storyId: data.id
      };

      const shortsData = await API.shorts.create({
        data: shareData
      });

      const story = convertStoryFromRaw(data, currentLocale ?? 'en', 'new');

      const storyData = {
        ...story,
        layerData: {
          ...story.layerData,
          shortDataId: shortsData.data.data.id
        }
      };

      await API.stories.update({
        story: storyData,
        appId: params.appId,
        groupId: params.groupId,
        currentLocale,
        defaultLocale,
        locales
      });

      dispatch(storiesSlice.actions.addStory({ story: storyData, type: params.type }));
      dispatch(appsManagerSlice.actions.updateStoriesCount(storiesCount + 1));

      if (params.isEditor) {
        dispatch(storiesSlice.actions.setSelectedLayersGroupId(story.layerData.layersGroupId));
      }

      // eslint-disable-next-line consistent-return
      return story;
    }
  }
);

export const fetchRemoveStory = createAsyncThunk(
  'stories/fetchRemoveStory',
  async (
    params: {
      appId: string;
      groupId: string;
      storyId: string;
      layersGroupId: string;
      story?: Story;
    },
    { dispatch }
  ) => {
    const { data, error } = await (await API.stories.remove(params)).data;

    if (params.story) {
      // @ts-ignore
      if (params.story.background.fileId) {
        // @ts-ignore
        API.storage.remove({ fileId: params.story.background.fileId });
      }

      params.story.widgets?.forEach((widget) => {
        // @ts-ignore
        if (widget.content.params.fillColor && widget.content.params.fillColor.fileId) {
          API.storage.remove({
            // @ts-ignore
            fileId: widget.content.params.fillColor.fileId
          });
          // @ts-ignore
        } else if (widget.content.params.imageUrl && widget.content.params.fileId) {
          API.storage.remove({
            // @ts-ignore
            fileId: widget.content.params.fileId
          });
        }
      });
    }

    if (data === 'ok' && !error) {
      dispatch(
        storiesSlice.actions.removeStory({
          layersGroupId: params.layersGroupId,
          storyId: params.storyId,
          updatePositions: true
        })
      );

      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.stories.removed')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.stories.removeError')
        })
      );
    }
  }
);

export const fetchRemoveLayersGroup = createAsyncThunk(
  'stories/fetchRemoveLayersGroup',
  async (
    params: {
      appId: string;
      groupId: string;
      layersGroupId: string;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const layers = Object.values(state.stories.stories[params.layersGroupId].layers) as Story[];
    const removedStories = layers.map((layer) => layer.id);
    let isRemoved = false;
    const storiesCount = state.appManager.current?.storiesCount ?? 0;

    removedStories.forEach((storyId) => {
      dispatch(
        storiesSlice.actions.removeStory({
          layersGroupId: params.layersGroupId,
          storyId
        })
      );
    });

    await asyncForEach(layers, async (layer: Story) => {
      const { data, error } = await (await API.stories.remove({ ...params, storyId: layer.id }))
        .data;

      if (data === 'ok' && !error) {
        isRemoved = true;
        // @ts-ignore
        if (layer.background.fileId) {
          // @ts-ignore
          API.storage.remove({ fileId: layer.background.fileId });
        }

        if (layer.widgets.length) {
          layer.widgets.forEach((widget) => {
            // @ts-ignore
            if (widget.content.params.fillColor && widget.content.params.fillColor.fileId) {
              API.storage.remove({
                // @ts-ignore
                fileId: widget.content.params.fillColor.fileId
              });
              // @ts-ignore
            } else if (widget.content.params.imageUrl && widget.content.params.fileId) {
              API.storage.remove({
                // @ts-ignore
                fileId: widget.content.params.fileId
              });
            }
          });
        }
      }
    });

    if (isRemoved) {
      dispatch(appsManagerSlice.actions.updateStoriesCount(storiesCount - removedStories.length));
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.stories.removed')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.stories.removeError')
        })
      );
    }
  }
);

export const fetchUpdateLayersGroupSchedule = createAsyncThunk<
  void,
  { appId: string; groupId: string; layersGroupId: string; time: ScheduleTimeType },
  { state: RootState }
>('stories/fetchUpdateLayersGroupSchedule', async (params, { dispatch, getState }) => {
  const { stories, appManager } = getState();

  const defaultLocale = appManager.current?.appLocalization.default;
  const currentLocale = appManager.currentLocale ?? defaultLocale;

  const locales = appManager.current?.appLocalization.languages.map((lang: any) => lang.shortName);
  const layers = Object.values(stories.stories[params.layersGroupId].layers);

  let isSuccessful = false;

  await asyncForEach(layers, async (story: Story) => {
    const storyData = {
      ...story,
      storyData: {
        ...story.storyData,
        [currentLocale]: {
          ...story.storyData?.[currentLocale],
          widgets: story.widgets,
          start_time: params.time.startTime,
          end_time: params.time.endTime
        }
      }
    };

    const { data } = await API.stories.update({
      ...params,
      story: storyData,
      currentLocale,
      defaultLocale,
      locales
    });

    if (data.data && !data.error) {
      isSuccessful = true;
      dispatch(
        storiesSlice.actions.setScheduleTime({
          id: story.id,
          layersGroupId: story.layerData.layersGroupId,
          time: params.time,
          currentLocale
        })
      );
    }
  });

  if (isSuccessful) {
    dispatch(
      informSlice.actions.addMessage({
        type: MessageTypes.SUCCESS,
        text: i18n.t('notification.stories.saved')
      })
    );
  } else {
    dispatch(
      informSlice.actions.addMessage({
        type: MessageTypes.ERROR,
        text: i18n.t('notification.stories.updateError')
      })
    );
  }
});

export const fetchUpdateAllGroupStoriesStatus = createAsyncThunk<
  void,
  {
    appId: string;
    groupId: string;
    status: StoryStatus;
    noNotification?: boolean;
  },
  { state: RootState }
>('stories/fetchUpdateAllGroupStoriesStatus', async (params, { dispatch, getState }) => {
  const { appManager } = getState();

  let isSuccessful = false;
  const currentLocale = appManager.currentLocale || appManager.current?.appLocalization.default;

  const { data } = await API.stories.updateStatuses({
    ...params,
    locale: currentLocale
  });

  if (data.data && !data.error) {
    isSuccessful = true;
    dispatch(
      storiesSlice.actions.setStatusToAllStories({
        status: params.status,
        currentLocale
      })
    );
  }

  if (params.noNotification) {
    return;
  }

  if (isSuccessful) {
    switch (params.status) {
      case StoryStatus.ACTIVE:
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.SUCCESS,
            text: i18n.t('notification.stories.publishedAll')
          })
        );

        break;
      case StoryStatus.DRAFT:
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.SUCCESS,
            text: i18n.t('notification.stories.draftedAll')
          })
        );
        break;
    }
  } else {
    dispatch(
      informSlice.actions.addMessage({
        type: MessageTypes.ERROR,
        text: i18n.t('notification.stories.updatedErrorAll')
      })
    );
  }
});

export const fetchUpdateLayersGroupStatus = createAsyncThunk<
  void,
  {
    appId: string;
    groupId: string;
    layersGroupId: string;
    status: StoryStatus;
    noNotification?: boolean;
  },
  { state: RootState }
>('stories/fetchUpdateLayersGroupStatus', async (params, { dispatch, getState }) => {
  const { stories, appManager } = getState();

  const locales = appManager.current?.appLocalization.languages.map((lang: any) => lang.shortName);

  const defaultLocale = appManager.current?.appLocalization.default;
  const currentLocale = appManager.currentLocale ?? defaultLocale;

  const layers = Object.values(stories.stories[params.layersGroupId].layers);

  let isSuccessful = false;

  await asyncForEach(layers, async (story: Story) => {
    const storyData = {
      ...story,
      storyData: {
        ...story.storyData,
        [currentLocale]: {
          ...story.storyData?.[currentLocale],
          status: params.status
        }
      }
    };

    const { data } = await API.stories.update({
      ...params,
      story: storyData,
      currentLocale,
      locales,
      defaultLocale,
      isStatusUpdate: true
    });

    if (data.data && !data.error) {
      isSuccessful = true;
      dispatch(
        storiesSlice.actions.setStatus({
          id: story.id,
          layersGroupId: story.layerData.layersGroupId,
          status: params.status,
          currentLocale
        })
      );
    }
  });

  if (params.noNotification) {
    return;
  }

  if (isSuccessful) {
    switch (params.status) {
      case StoryStatus.ACTIVE:
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.SUCCESS,
            text: i18n.t('notification.stories.published')
          })
        );

        break;
      case StoryStatus.DRAFT:
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.SUCCESS,
            text: i18n.t('notification.stories.drafted')
          })
        );
        break;
    }
  } else {
    dispatch(
      informSlice.actions.addMessage({
        type: MessageTypes.ERROR,
        text: i18n.t('notification.stories.updateError')
      })
    );
  }
});

export const fetchUpdateStoryStatus = createAsyncThunk<
  void,
  { appId: string; groupId: string; storyId: string; layersGroupId: string; status: StoryStatus },
  { state: RootState }
>('stories/fetchUpdateStoryStatus', async (params, { dispatch, getState }) => {
  const { stories, appManager } = getState();

  const defaultLocale = appManager.current?.appLocalization.default;
  const currentLocale = appManager.currentLocale || defaultLocale;

  const locales = appManager.current?.appLocalization.languages.map((lang: any) => lang.shortName);
  const story = stories.stories[params.layersGroupId].layers[params.storyId];

  const storyData = {
    ...story,
    storyData: {
      ...story.storyData,
      [currentLocale]: {
        ...story.storyData?.[currentLocale],
        widgets: story.widgets,
        status: params.status
      }
    }
  };

  const { data } = await API.stories.update({
    ...params,
    story: storyData,
    currentLocale,
    locales,
    defaultLocale
  });

  if (data.data && !data.error) {
    dispatch(
      storiesSlice.actions.setStatus({
        id: params.storyId,
        layersGroupId: params.layersGroupId,
        status: params.status,
        currentLocale
      })
    );

    switch (params.status) {
      case StoryStatus.ACTIVE:
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.SUCCESS,
            text: i18n.t('notification.stories.published')
          })
        );

        break;
      case StoryStatus.DRAFT:
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.SUCCESS,
            text: i18n.t('notification.stories.drafted')
          })
        );
        break;
    }
  } else {
    dispatch(
      informSlice.actions.addMessage({
        type: MessageTypes.ERROR,
        text: i18n.t('notification.stories.updateError')
      })
    );
  }
});

export const fetchSaveStories = createAsyncThunk(
  'stories/fetchSaveStories',
  async (
    params: {
      appId: string;
      groupId: string;
      stories: Story[];
      noNotification?: boolean;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;

    const defaultLocale = state.appManager.current?.appLocalization.default;
    const currentLocale = state.appManager.currentLocale ?? defaultLocale;

    if (!currentLocale) {
      return;
    }

    const locales = state.appManager.current?.appLocalization.languages.map(
      (lang: any) => lang.shortName
    );

    await Promise.all(
      params.stories.map((story) =>
        API.stories.update({ ...params, story, currentLocale, locales, defaultLocale })
      )
    );

    dispatch(storiesSlice.actions.setChangedObjects([]));

    if (!params.noNotification) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          // @ts-ignore
          text: i18n.t('notification.stories.saved')
        })
      );
    }
  }
);

export const fetchSaveStory = createAsyncThunk(
  'stories/fetchSaveStory',
  async (
    params: {
      appId?: string;
      groupId?: string;
      templateId?: string;
      story: Story;
    },
    { dispatch, getState }
  ) => {
    const currentStoriesSlice = params.templateId ? templateStoriesSlice : storiesSlice;

    dispatch(
      currentStoriesSlice.actions.setChangedObjectSavingStatusByLayersGroupId({
        layersGroupId: params.story.layerData.layersGroupId,
        status: SaveStatus.SAVING
      })
    );

    const state = getState() as RootState;

    const defaultLocale = state.appManager.current?.appLocalization.default;
    const currentLocale = state.appManager.currentLocale ?? defaultLocale;

    const locales = state.appManager.current?.appLocalization.languages.map(
      (lang: any) => lang.shortName
    );

    if (params.appId && params.groupId && currentLocale) {
      await API.stories.update({
        ...params,
        appId: params.appId,
        groupId: params.groupId,
        currentLocale,
        defaultLocale,
        locales
      });
    } else if (params.templateId && currentLocale) {
      await API.templateStories.update({
        ...params,
        templateId: params.templateId,
        currentLocale,
        defaultLocale,
        locales
      });
    }

    dispatch(
      currentStoriesSlice.actions.setChangedObjectSavingStatusByLayersGroupId({
        layersGroupId: params.story.layerData.layersGroupId,
        status: SaveStatus.SAVED
      })
    );
  }
);

export const fetchUpdateStory = createAsyncThunk(
  'stories/fetchUpdateStory',
  async (
    params: {
      appId?: string;
      groupId?: string;
      story: Story;
    },
    { dispatch, getState }
  ) => {
    dispatch(
      storiesSlice.actions.setChangedObjectSavingStatusByLayersGroupId({
        layersGroupId: params.story.layerData.layersGroupId,
        status: SaveStatus.SAVING
      })
    );

    const state = getState() as RootState;

    const defaultLocale = state.appManager.current?.appLocalization.default;
    const currentLocale = state.appManager.currentLocale ?? defaultLocale;

    const locales = state.appManager.current?.appLocalization.languages.map(
      (lang: any) => lang.shortName
    );

    dispatch(storiesSlice.actions.updateStoryWithoutSaving(params.story));

    if (params.appId && params.groupId && currentLocale) {
      await API.stories.update({
        ...params,
        appId: params.appId,
        groupId: params.groupId,
        currentLocale,
        locales,
        defaultLocale
      });
    }

    dispatch(
      storiesSlice.actions.setChangedObjectSavingStatusByLayersGroupId({
        layersGroupId: params.story.layerData.layersGroupId,
        status: SaveStatus.SAVED
      })
    );
  }
);

export const fetchSaveWidgetImage = createAsyncThunk(
  'stories/fetchSaveWidgetImage',
  async (
    params: {
      appId?: string;
      groupId?: string;
      templateId?: string;
      story: Story;
      storyWidth: number;
      storyHeight: number;
      widget: WidgetObjectType;
      sizes?: WidgetElemetsSizes;
    },
    { dispatch, getState }
  ) => {
    const currentStoriesSlice = params.templateId ? templateStoriesSlice : storiesSlice;

    dispatch(
      currentStoriesSlice.actions.setChangedObjectSavingStatusByLayersGroupId({
        layersGroupId: params.story.layerData.layersGroupId,
        status: SaveStatus.SAVING
      })
    );

    const state = getState() as RootState;

    const newStory = {
      ...params.story
    };

    const imageParts = getWidgetImageParts(params.widget.content.type);

    if (imageParts.widget) {
      const widgetLayoutData = getHtmlWidgetLayout(
        params.widget.content.type,
        params.widget.positionByResolutions[`${params.storyWidth}x${params.storyHeight}`],
        params.widget.content.params,
        state.fonts.fonts
      );

      if (widgetLayoutData) {
        try {
          const { data } = await API.stories.html2img({ js: '', ...widgetLayoutData });

          if (data && !data.error) {
            newStory.widgets = newStory.widgets.map((widget) => {
              if (widget.id === params.widget.id) {
                return {
                  ...widget,
                  content: {
                    ...widget.content,
                    widgetImage: data.data
                  }
                };
              }

              return widget;
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    }

    if (imageParts.title && params.sizes?.title) {
      const textParams = getAdaptedTextParams(
        params.widget.content.type,
        params.widget.content.params,
        'title',
        params.sizes?.title.fontSize
      );

      if (textParams) {
        const titleLayoutData = getHtmlTextLayout(
          params.sizes?.title.width,
          params.sizes?.title.height,
          textParams,
          state.fonts.fonts
        );

        if (titleLayoutData) {
          try {
            const { data } = await API.stories.html2img({ js: '', ...titleLayoutData });

            if (data && !data.error) {
              newStory.widgets = newStory.widgets.map((widget) => {
                if (widget.id === params.widget.id) {
                  return {
                    ...widget,
                    content: {
                      ...widget.content,
                      widgetTitleImage: data.data
                    }
                  };
                }
                return widget;
              });
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
    }

    if (imageParts.answers && params.sizes?.answers) {
      // @ts-ignore
      const answersParams = params.widget.content.params.answers
        .map((answer: any) => ({
          id: answer.id,
          params: getAdaptedTextParams(
            params.widget.content.type,
            params.widget.content.params,
            'answer',
            params.sizes?.answers?.[answer.id].fontSize,
            answer.title
          )
        }))
        .filter((answerParams: any) => !!answerParams);

      await asyncForEach(answersParams, async (answer: any) => {
        const answerLayoutData = getHtmlTextLayout(
          params.sizes?.answers?.[answer.id].width ?? 0,
          params.sizes?.answers?.[answer.id].height ?? 0,
          answer.params,
          state.fonts.fonts
        );

        if (answerLayoutData) {
          try {
            const { data } = await API.stories.html2img({ js: '', ...answerLayoutData });

            if (data && !data.error) {
              // @ts-ignore
              newStory.widgets = newStory.widgets.map((widget) => {
                if (widget.id === params.widget.id) {
                  return {
                    ...widget,
                    content: {
                      ...widget.content,
                      params: {
                        ...widget.content.params,
                        // @ts-ignore
                        answers: widget.content.params.answers.map((answerItem: any) => {
                          if (answerItem.id === answer.id) {
                            return {
                              ...answerItem,
                              answerTitleImage: data.data
                            };
                          }

                          return answerItem;
                        })
                      }
                    }
                  };
                }

                return widget;
              });
            }
          } catch (e) {
            console.error(e);
          }
        }
      });
    }

    const defaultLocale = state.appManager.current?.appLocalization.default;
    const currentLocale = state.appManager.currentLocale ?? defaultLocale;
    const locales = state.appManager.current?.appLocalization.languages.map(
      (lang: any) => lang.shortName
    );

    if (params.appId && params.groupId && currentLocale) {
      await API.stories.update({
        ...params,
        appId: params.appId,
        groupId: params.groupId,
        story: newStory,
        currentLocale,
        defaultLocale,
        locales
      });
    } else if (params.templateId && currentLocale) {
      await API.templateStories.update({
        ...params,
        templateId: params.templateId,
        story: newStory,
        currentLocale,
        locales
      });
    }

    dispatch(
      currentStoriesSlice.actions.setChangedObjectSavingStatusByLayersGroupId({
        layersGroupId: params.story.layerData.layersGroupId,
        status: SaveStatus.SAVED
      })
    );
  }
);
