import React, { useCallback, useEffect, useState } from 'react';
import block from 'bem-cn';
import hexOpacity from 'hex-opacity';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { ColorState, Picker } from '../Picker/Picker';
import './GradientPicker.scss';

const b = block('GradientPicker');

interface Props {
  gradient: string[];
  isOpen?: boolean;
  onChange(gradient: string[]): void;
}

type GradientState = {
  first: ColorState;
  second: ColorState;
  changed: boolean;
};

const defaultGradient = {
  first: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
    hsl: { h: 0, s: 0, l: 0, a: 1 }
  },
  second: {
    hex: '#FFFFFF',
    rgb: { r: 255, g: 255, b: 255, a: 1 },
    hsl: { h: 0, s: 0, l: 100, a: 1 }
  }
};

const GradientPicker: React.FC<Props> = (props) => {
  const { gradient, isOpen, onChange } = props;
  const [currentColor, setCurrentColor] = useState<'first' | 'second'>('first');
  const [gradientState, setGradient] = useState<GradientState>({
    first: defaultGradient.first,
    second: defaultGradient.second,
    changed: false
  });
  const selectedLayersGroupId = useSelector(
    (store: RootState) => store.stories.editor.selectedLayersGroupId
  );

  useEffect(() => {
    if (isOpen) {
      setGradient({
        first: {
          hex: gradient[0]
        },
        second: {
          hex: gradient[1]
        },
        changed: false
      });
    }
  }, [isOpen, selectedLayersGroupId]);

  const handleChangeColor = useCallback(
    (colorParams: ColorState) => {
      setGradient({
        ...gradientState,
        [currentColor]: {
          ...colorParams,
          hex: hexOpacity.create(colorParams.hex, colorParams.rgb?.a)
        },
        changed: true
      });
    },
    [gradientState, currentColor]
  );

  const handleChangeTwoColors = useCallback((colorParams: Array<string>) => {
    setGradient({
      first: { ...gradientState.first, hex: colorParams[0] },
      second: { ...gradientState.second, hex: colorParams[1] },
      changed: true
    });
  }, []);

  useEffect(() => {
    if (gradientState.changed && gradientState.first.hex && gradientState.second.hex) {
      onChange([gradientState.first.hex, gradientState.second.hex]);
    }
  }, [gradientState]);

  return (
    <div className={b()}>
      <div
        className={b('selector')}
        style={{
          background: `linear-gradient(90deg, ${gradientState.first.hex} 0%, ${gradientState.second.hex} 100%)`
        }}
      >
        <button
          className={b('color-btn', { current: currentColor === 'first', first: true })}
          onClick={() => setCurrentColor('first')}
        >
          first
        </button>
        <button
          className={b('color-btn', { current: currentColor === 'second', second: true })}
          onClick={() => setCurrentColor('second')}
        >
          second
        </button>
      </div>
      <Picker
        color={gradientState[currentColor].hex}
        gradientColors={[gradientState.first, gradientState.second]}
        type="gradient"
        onChange={handleChangeColor}
        onChangeGradient={handleChangeTwoColors}
        onChangeGradientColor={handleChangeColor}
        onToggleGradientColor={(color: 'first' | 'second') => {
          setCurrentColor(color);
        }}
      />
    </div>
  );
};

export default GradientPicker;
