import React, { useState, useEffect, memo } from 'react';
import block from 'bem-cn';
import { STORY_HEIGHT, STORY_HEIGHT_LARGE, STORY_WIDTH, Story } from '@features';
import { renderBackgroundStyles } from '@modules/EditorModule/utils';
import { StoryVideoBackground } from '@features/stories/components';
import { getWidgetPositionLimits } from '@features/stories/consts';
import { useCardScale } from '@hooks';
import { renderWidgetControl } from '../../utils';
import './StoryPreview.scss';

const b = block('StoryPreview');

interface StoryPreviewProps {
  story: Story;
  scaleIndex?: number;
  isOnboarding?: boolean;
  dashboardVariant?: boolean;
  templateVariant?: boolean;
  className?: string;
}

export const StoryPreview: React.FC<StoryPreviewProps> = memo((props) => {
  const { story, isOnboarding, dashboardVariant, templateVariant, className, scaleIndex } = props;
  const [isVideoLoading, setVideoLoading] = useState(false);

  useEffect(() => {
    if (story.background.type === 'video') {
      setVideoLoading(true);
    }
  }, [story.background.type]);

  const scale = useCardScale();

  return (
    <div
      className={`${b({
        dashboardVariant,
        templateVariant
      })} ${className ?? ''}`}
      style={{
        background: renderBackgroundStyles(story.background),
        transform: !dashboardVariant
          ? `scale(${scaleIndex ?? scale}) ${templateVariant ? 'translate(-50%, -50%)' : ''}`
          : undefined
      }}
    >
      {story.widgets.map((widget) => {
        const positionLimits = getWidgetPositionLimits(widget.content.type);
        const currentPosition = widget.positionByResolutions[`${STORY_WIDTH}x${STORY_HEIGHT}`];

        return (
          <div
            className={b('widget')}
            key={widget.id}
            style={{
              width: positionLimits.isAutoWidth ? 'auto' : currentPosition.width,
              height: positionLimits.isAutoHeight ? 'auto' : currentPosition.height,
              left: currentPosition.x,
              top: currentPosition.y,
              transform: `rotate(${currentPosition.rotate}deg)`
            }}
          >
            {renderWidgetControl({
              content: widget.content,
              position: currentPosition,
              positionLimits,
              elementsSize: currentPosition.elementsSize
            })}
          </div>
        );
      })}

      {story.background.type === 'video' && (
        <StoryVideoBackground
          isFilled={story?.background.isFilled || isOnboarding}
          isLoading={isVideoLoading}
          src={story.background.value}
          onLoadEnd={() => {
            setVideoLoading(false);
          }}
        />
      )}
    </div>
  );
});
